import {TEXTO_VISUAL} from '../../../constantes'
import {SpanNombresSegunTipoPrecios} from './SpanNombresSegunTipoPrecios'

const TEXTO_MODAL = TEXTO_VISUAL.MODAL.PRECIOS_EN_CERO

export const InformacionPreciosEnCero = ({serviciosOpaquetesNoValidos}) => {
  return (
    <div className="w-full flex flex-wrap m-2 text-cendiatra font-bold uppercase overflow-y-auto max-h-96 ">
      {serviciosOpaquetesNoValidos.map((servicioOPaquete) => {
        const spanSedes = (
          <SpanNombresSegunTipoPrecios
            titulo={TEXTO_MODAL.SEDES}
            precios={servicioOPaquete.precios_sedes}
          />
        )

        const spanAliados = (
          <SpanNombresSegunTipoPrecios
            titulo={TEXTO_MODAL.ALIADOS}
            precios={servicioOPaquete.precios_aliados}
          />
        )

        return (
          <p className="w-full" key={servicioOPaquete.nombre}>
            - {servicioOPaquete.nombre}: {spanSedes} {spanAliados}
          </p>
        )
      })}
      <p className="w-full text-center mt-10">{TEXTO_MODAL.DESEA_GUARDAR}</p>
    </div>
  )
}
